import bunner1 from '../images/kotOne.png';
import bunner2 from '../images/kotTwo.png';
import bunner3 from '../images/kotThree.png';


export const sliderData = [{
    id: 1,
    image: bunner1,
},
{
    id: 2,
    image: bunner2,
},
{
    id: 3,
    image: bunner3,
}];